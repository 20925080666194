




































import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class Todo extends Vue {
  @PropSync('visible', { type: Boolean }) syncVisible!: boolean;
  @Prop() title!: string;

  @Watch('visible')
  async onVisibleChange() {
    console.log(1);
  }

  saveAndAdd() {
    this.$router.push('/setting/sheet');
    this.syncVisible = false;
  }

  toModel() {
    this.$router.push('/model-form');
    this.syncVisible = false;
  }
}
