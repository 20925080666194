



































































































import { FilterItem } from '@/interfaces/filter-items';
import MenuMixin from '@/mixins/menu-mixin';
import TablePageMixin from '@/mixins/table-page-mixin';
import { mixins } from 'vue-class-component';
import { Vue, Component } from 'vue-property-decorator';
import MinCard from './min-card.vue';
import TemplateInfo from './template-info.vue';
import { columns, list } from '../review/consts';
@Component({
  components: { MinCard, TemplateInfo }
})
export default class Todo extends mixins(MenuMixin, TablePageMixin) {
  activeKey = 1;
  reVisible: boolean = false;

  created() {
    this.total = 100;
  }

  saveAndAdd() {
    this.$router.push('/setting/sheet');
    this.reVisible = false;
  }

  open() {
    this.$router.push('/setting/sheet');
  }

  openInterface() {
    this.$router.push('/setting/interface');
  }

  logVisible: boolean = false;
  log() {
    this.logVisible = true;
  }

  confirm(msg: string) {
    this.$confirm({
      title: '提示',
      content: msg,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {},
      onCancel: async () => {}
    });
  }

  showCard = [
    { title: '待提交结算单模板', number: 5, type: 0, tip: 'xxxx', unit: '预警' },
    { title: '待审批结算单模板', number: 4, type: 1, tip: 'xxxx', unit: '预警' },
    { title: '待发布结算单模板', number: 5, type: 2, tip: 'xxxx', unit: '预警' },
    { title: '已发布结算单模板', number: 20, type: 3, tip: 'xxxx', unit: '预警' }
  ];

  filterItems: FilterItem[] = [
    { label: '模板编码', key: 'alertCode', type: 'input' },
    { label: '模板名称', key: 'alertMessage', type: 'input' },
    { label: '模板状态', key: 'alertDate', type: 'select', options: [] },
    { label: '经办人', key: 'alertHandleDueDate', type: 'input' },
    { label: '经办日期', key: 'handleDate', type: 'datePicker' },
    { label: '业务类型', key: 'reviewDate', type: 'select', options: [] },
    { label: '表单模板', key: 'inspectDate', type: 'select', options: [] },
    { label: '商户名称', key: 'alertHandlerName', type: 'input' },
    { label: '合同名称', key: 'alertReviewerName', type: 'input' }
  ];

  columns = columns;

  list = list;

  filter: any = {};
  page = 1;
  excuteFilter(res: object) {
    this.page = 1;
    this.filter = res;
  }

  resetFilter() {
    this.page = 1;
    this.filter = {};
  }

  handleBatch() {}
}
